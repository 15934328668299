
function About(){
    return(
        <>
            <h1>This is our About page</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam aliquam, error, deserunt a, quidem perspiciatis quae odit temporibus tempora recusandae esse molestiae sint iste quos dolorum sit. Nihil dolore dolores rerum omnis, a architecto?</p>
            
        </>
    );
}

export default About;