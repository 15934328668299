import './App.css';
import Navbar from './Components/Navbar.js';
import TextForm from './Components/TextForm.js';
import Alert from './Components/Alert.js'
import About from './About.js'
import React, { useState } from 'react'
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { Outlet } from 'react-router-dom';


export default function App() {
  const [style, setStyle] = useState({ color: 'black', backgroundColor: 'white', });
  const [mode, setMode] = useState("Light");
  const [alert, setAlert] = useState(null);
  function toogleStyle() {
    if (mode === 'Light') {
      setStyle({
        color: 'white', backgroundColor: 'black',
      })
      document.body.style.backgroundColor = 'black';
      setMode("Dark");
      showAlert("Dark mode has been enabled", "success")
    }
    else {
      setStyle({
        color: 'black', backgroundColor: 'white',
      })
      document.body.style.backgroundColor = 'white';
      setMode("Light");
      showAlert("Light mode has been enabled", "success")
    }
  }

  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    })
    setTimeout(() => {
      setAlert(null);
    }, 1500)
  }
  return (
    <>
      <BrowserRouter>
        <Routes>

          {/* Navbar: starts*/}
          <Route path='/' element={
            <React.Fragment>
              <Navbar showAlert={showAlert} mode={mode} />
              <Outlet />
            </React.Fragment>
          } >

            {/* Page 1: Home*/}
            <Route index element={
              <React.Fragment>
                <Alert alert={alert} />
                <div style={style} className="container my-3" >
                  <TextForm showAlert={showAlert} style={style} heading="Enter the text to Modify" />
                  <button onClick={toogleStyle} style={style} type='button' className='btn btn-primary my-1'>{mode}</button>
                </div>
              </React.Fragment>
            }></Route>

            {/* Page 2: About*/}
            <Route path='/About' element={<About />} />

            {/* Navbar : Ends */}
          </Route>

        </Routes>
      </BrowserRouter>


    </>
  );
}





//later copy from here incase needed

{/* <Navbar showAlert={showAlert} mode={mode} title="Dream League Soccer" /> */ }
{/* <Alert alert={alert} />
    <div style={style} className="container my-3" >
      <TextForm showAlert={showAlert} style={style} heading="Enter the text to Modify" />
      <button onClick={toogleStyle} style={style} type='button' className='btn btn-primary my-1'>{mode}</button>
    </div>
    <About /> */}
