import React, { useState } from 'react'

function TextForm(props) {
    let [text, setText] = useState("Set text");
    function upperClickHandler() {
        let newText = text.toUpperCase();
        setText(newText);
        props.showAlert("Converted to Upper Case", "success");
    }
    function lowerClickHandler() {
        let newText = text.toLowerCase();
        setText(newText);
        props.showAlert("Converted to Lower Case", "success");
    }
    function clearClickHandler() {
        setText("");
        props.showAlert("Text cleared", "success");
    }
    function copyClickHandler() {
        navigator.clipboard.writeText(text);
        props.showAlert("Text copied", "success");
    }
    function onChangeHandler(event) {
        setText(event.target.value);
    }
    function removeExtraSpace(){
        let array = text.split(" ");
        let newText = "";
        array.forEach((ele)=>{
            if((ele < 'a' || ele > 'z') && (ele <'A' || ele > 'Z')) {
                newText += ele;
                console.log("punctuation mark");
            }
            else if(ele !== "") newText += " "  + ele;
        })
        newText.trim();
        newText.trim(); 
        setText(newText);
        props.showAlert("Removed extra spaces", "success");
    }
    return (
        <>
            <h1>{props.heading}</h1>
            <div className="mb-3">
                <textarea style={props.style} className="form-control" value={text} placeholder='Welcome Back' onChange={onChangeHandler} id="exampleFormControlTextarea1" rows="8"></textarea>
            </div>
            <button style={props.style} className="btn btn-primary mx-1" onClick={upperClickHandler}>Upper Case</button>
            <button style={props.style} className="btn btn-primary mx-1" onClick={lowerClickHandler}>Lower Case</button>
            <button style={props.style} className="btn btn-primary mx-1" onClick={clearClickHandler}>Clear Text</button>
            <button style={props.style} className="btn btn-primary mx-1" onClick={copyClickHandler}>Copy Text</button>
            <button style={props.style} className="btn btn-primary mx-1" onClick={removeExtraSpace}>Remove Extra Space</button>
        </>
    );
}

export default TextForm;



